<template>
  <div class="flex flex-col gap-3">
    <div class="flex justify-end">
      <p>Total DN Value : {{ priceFormat(totalDnValue) }}</p>
    </div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th width="7%">Action</th>
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.purchasePaymentRequest"
              :class="tr.class"
            >
              <vs-td class="whitespace-no-wrap">
                <template>
                  <div class="mt-4 flex justify-between">
                    <vx-tooltip text="Show Invoice" class="mr-4">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        v-on:click.stop="handleShow(tr)"
                      />
                    </vx-tooltip>
                  </div>
                </template>
              </vs-td>
              <vs-td>
                Payment Document : {{ tr.payment_code }} <br />
                Debit Note Adjustment : {{ tr.adjustment_code }} <br />
                Debit Note Document : {{ tr.dn_code }}
              </vs-td>
              <vs-td> ({{ tr.supplier_code }}) {{ tr.supplier_name }} </vs-td>
              <vs-td>
                Payment :
                {{
                  tr.payment_value
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}<br />
                Adjustment :
                {{
                  tr.adjustment_value
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
        <!-- Approve Prompt -->
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <detail-div @close="handleClose" :selected="selectedData" />
          </vs-row>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import detail from "./detail.vue";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Supplier",
          value: "supplier_code",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
    };
  },
  computed: {
    totalDnValue() {
      let totalValue = 0;

      if (!this.responseData || Object.keys(this.responseData).length === 0) {
        return totalValue;
      }

      totalValue = this.responseData.purchasePaymentRequest.reduce((acc, item) => {
        return acc + item.dn_value;
      }, 0);
      return totalValue;
    },
  },
  watch: {},
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/purchase-payment-request/apply", {
            params: {
              status: 3,
              type: 2,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    handleClose() {
      this.reloadData(this.params);
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.purchasePaymentRequest;
          this.responseData = r.data;
          this.responseData.length = r.data.purchasePaymentRequest.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
