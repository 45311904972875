<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Detail</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <template v-for="proposal in paymentProposal">
          <div v-bind:key="proposal.ID">
            <vs-divider style="width: 100%"
              ><b>{{ proposal.Code }}</b></vs-divider
            >
            Code Invoice : {{ proposal.PurchaseInvoiceCode }}<br />
            Paid By Debit Note :
            {{
              proposal.PaidValue.toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}<br />
            <!-- <vs-divider style="width: 100%"></vs-divider> -->
          </div>
        </template>

        <vs-divider v-if="schemaLines.length > 0" style="width: 100%"
          ><b>Debit Note Apply Schema</b></vs-divider
        >
        <vs-table v-if="schemaLines.length > 0" :data="schemaLines">
          <template slot="thead">
            <vs-th> # </vs-th>
            <vs-th> Applied To </vs-th>
            <vs-th> Code </vs-th>
            <vs-th> Remaining Before </vs-th>
            <vs-th> Debit Note Code </vs-th>
            <vs-th> Debit Note Remaining Before </vs-th>
            <vs-th> Paid </vs-th>
            <vs-th> Remaining After </vs-th>
            <vs-th> Debit Note Remaining After </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="indextr + 1">
                {{ indextr + 1 }}
              </vs-td>

              <vs-td :data="getAppliedTo(tr.CreditNoteSupplierID)">
                {{ getAppliedTo(tr.CreditNoteSupplierID) }}
              </vs-td>

              <vs-td
                :data="
                  tr.PurchaseInvoiceID
                    ? tr.PurchaseInvoiceCode
                    : tr.CreditNoteSupplierCode
                "
              >
                {{
                  tr.PurchaseInvoiceID
                    ? tr.PurchaseInvoiceCode
                    : tr.CreditNoteSupplierCode
                }}
              </vs-td>

              <vs-td
                :data="
                  tr.PurchaseInvoiceID
                    ? tr.PurchaseInvoiceBeforeValue
                    : tr.CreditNoteSupplierBeforeValue
                "
              >
                {{
                  tr.PurchaseInvoiceID
                    ? priceFormat(tr.PurchaseInvoiceBeforeValue)
                    : priceFormat(tr.CreditNoteSupplierBeforeValue)
                }}
              </vs-td>

              <vs-td :data="tr.DebitNoteCode">
                {{ tr.DebitNoteCode }}
              </vs-td>

              <vs-td :data="tr.DebitNoteBeforeValue">
                {{ priceFormat(tr.DebitNoteBeforeValue) }}
              </vs-td>

              <vs-td :data="tr.ClearingValue">
                {{ priceFormat(tr.ClearingValue) }}
              </vs-td>

              <vs-td
                :data="
                  tr.PurchaseInvoiceID
                    ? tr.PurchaseInvoiceAfterValue
                    : tr.CreditNoteSupplierAfterValue
                "
              >
                {{
                  tr.PurchaseInvoiceID
                    ? priceFormat(tr.PurchaseInvoiceAfterValue)
                    : priceFormat(tr.CreditNoteSupplierAfterValue)
                }}
              </vs-td>

              <vs-td :data="tr.DebitNoteAfterValue">
                {{ priceFormat(tr.DebitNoteAfterValue) }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
    selected: Object,
  },
  methods: {
    initialState() {
      return {
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        bank: [],
        paymentProposal: [],
        optionSupplier: [],
        optionPaymentProposal: [],
        optionBankOperatingUnit: [],
        optionBankSupplier: [],
        reversalDate: "",
        notes: [],
        Newnote: "",
        schemaLines: [],
      };
    },
    handleReversal() {
      // //console.log(this.operatingUnitBank,this.supplierBank)

      const params = {
        id: this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 8,
        type: 1,
        reference_code: this.reference_code,
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/purchase-payment-request/change-status", params)
        .then((result) => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getOptionPaymentProposal(query) {
      //console.log(this.supplier)
      this.$http
        .get("/api/v1/purchase-payment-proposal", {
          params: {
            status: 1,
            request_code: this.selected.Code,
            search: query,
            length: 100,
            still_empty: 1,
            // page: params.page,
            // order: params.sort,
            // sort: params.order,
            supplier_code: this.supplier.Code,
          },
        })
        .then((r) => {
          this.optionPaymentProposal = r.data.purchasePaymentProposal;
          //console.log(r)
        })
        .catch((e) => {
          //console.log(e)
        });
    },
    getOptionBankOperationUnit() {
      this.$http
        .get("/api/v1/purchase-payment-proposal/bank-operating-unit")
        .then((r) => {
          this.optionBankOperatingUnit = r.data.dataOperatingUnitBank;
          this.bank = r.data.dataBank;
          console.log();
          if (this.selected.ID) {
            r.data.dataOperatingUnitBank.map((v) => {
              if (
                v.BankID == this.selected.BankID &&
                v.AccountNumber == this.selected.AccountNumber
              ) {
                this.operatingUnitBank = v;
              }
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getOptionSupplierBank() {
      this.$http
        .get("/api/v1/pi/supplier-account/" + this.supplier.ID)
        .then((r) => {
          this.optionBankSupplier = r.data.account;
          //console.log(this.selected)
          if (this.selected.ID) {
            r.data.account.map((v) => {
              if (
                v.BankName == this.selected.ToBankName &&
                v.AccountNumber == this.selected.ToAccountNumber
              ) {
                this.supplierBank = v;
              }
            });
          }
          //console.log(r)
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/purchase-payment-request/apply/line/" + this.selected.ID)
        .then((result) => {
          // this.$vs.loading.close();
          this.paymentProposal = [];
          result.data.purchasePaymentProposal.map((v) => {
            this.paymentProposal.push(v);
          });
          this.notes = this.selected.Notes
            ? this.selected.Notes.toString().split(";")
            : [];

          this.schemaLines = result.data.schemaLines;
          //console.log(result)
        })
        .catch((e) => {
          this.$vs.loading.close();
          console.error(e);
        });
    },
    getOptionSupplier(query) {
      if (query.length <= 2) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
            if (this.selected.ID) {
              resp.data.records.map((v) => {
                if (v.Code == this.selected.SupplierCode) {
                  this.supplier = v;
                }
              });
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getAppliedTo(cn_supplier_id) {
      if (cn_supplier_id) {
        return "Credit Note Supplier";
      } else {
        return "Purchase Invoice";
      }
    },
  },
  mounted() {
    console.log(this.selected);
    this.getOptionBankOperationUnit();
    this.getData();
    this.getOptionSupplier(this.selected.supplier_name);
    this.reversalDate = moment();
    // this.getOptionPaymentTerm();
    // this.getOptionSupplier();
    // this.getOptionWarehouse();
  },
  computed: {},
  watch: {
    "supplier.ID"() {
      this.getOptionSupplierBank();
    },
    "selected.ID"() {
      //console.log("selected change")
      this.getData();
      this.getOptionSupplier(this.selected.SupplierName);
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
